import React from "react";
import {Image} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import QuizzesGridView from "../Quizzes/QuizzesGridView";
import arrowLeft from "../../assets/images/arrow-left.svg";

import {useTranslation} from "react-i18next";

export const QuizzesBeta = (props: any) => {
  const {t} = useTranslation("translation");
  const history = useHistory();
  const {isArchive} = props;

  return (
      <>
        {/* {iframeLoading && <Loading />} */}

        <section className="page-mid-wraper h-without-foter">
          <div className="row">
            {isArchive && (
                <div className="col-md-12">
                  <button
                      className="btn p-0 btn-back"
                      onClick={() => {
                        history.goBack();
                      }}
                  >
                    <Image src={arrowLeft}/>
                    {t("Return to previous page")}
                  </button>
                </div>
            )}
            <div className="col-md-12">
              <div className="mb-12 d-flex justify-content-between align-items-center">
                <div>
                  <h1 className="mb-2">
                    {isArchive
                        ? t("Archived Items")
                        : t("Exercises")}
                  </h1>
                </div>
              </div>
              <QuizzesGridView isArchive={isArchive}/>
            </div>
          </div>
        </section>
      </>
  );
};

export default QuizzesBeta;
