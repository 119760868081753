import { FC } from "react";

export const Maximize: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63636 3.5C8.63636 3.22386 8.41251 3 8.13636 3H1.5C0.671572 3 0 3.67157 0 4.5V14.5C0 15.3284 0.671573 16 1.5 16H11.5C12.3284 16 13 15.3284 13 14.5V7.86364C13 7.58749 12.7761 7.36364 12.5 7.36364C12.2239 7.36364 12 7.58749 12 7.86364V14.5C12 14.7761 11.7761 15 11.5 15H1.5C1.22386 15 1 14.7761 1 14.5V4.5C1 4.22386 1.22386 4 1.5 4H8.13636C8.41251 4 8.63636 3.77614 8.63636 3.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0.5C16 0.223858 15.7761 0 15.5 0H10.5C10.2239 0 10 0.223858 10 0.5C10 0.776142 10.2239 1 10.5 1H14.2929L6.14645 9.14645C5.95118 9.34171 5.95118 9.65829 6.14645 9.85355C6.34171 10.0488 6.65829 10.0488 6.85355 9.85355L15 1.70711V5.5C15 5.77614 15.2239 6 15.5 6C15.7761 6 16 5.77614 16 5.5V0.5Z"
      fill="currentColor"
    />
  </svg>
);

export const Question: FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM6.18338 6.7868H7.11092C7.26625 6.7868 7.38976 6.66015 7.41063 6.50622C7.5107 5.76808 8.01731 5.23004 8.92037 5.23004C9.69161 5.23004 10.3986 5.61566 10.3986 6.54401C10.3986 7.25812 9.97726 7.58661 9.31313 8.08649C8.55618 8.63635 7.95632 9.27905 7.99917 10.3217L8.00251 10.5656C8.00462 10.7194 8.12991 10.843 8.28373 10.843H9.19613C9.35146 10.843 9.47738 10.717 9.47738 10.5617V10.4431C9.47738 9.63611 9.78445 9.40045 10.6128 8.77203C11.2984 8.25073 12.0125 7.6723 12.0125 6.45831C12.0125 4.75873 10.5771 3.9375 9.00607 3.9375C7.5806 3.9375 6.01834 4.60194 5.912 6.50942C5.90346 6.66266 6.02989 6.7868 6.18338 6.7868ZM8.79897 14.035C9.48452 14.035 9.95583 13.5923 9.95583 12.9924C9.95583 12.3712 9.48452 11.9355 8.79897 11.9355C8.14199 11.9355 7.66354 12.3712 7.66354 12.9924C7.66354 13.5923 8.14199 14.035 8.79897 14.035Z"
      fill="currentColor"
    />
  </svg>
);

export const Bell: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C9.10457 16 10 15.1046 10 14H6C6 15.1046 6.89543 16 8 16Z"
      fill="#001DE0"
    />
    <path
      d="M8 1.91825L7.20281 2.07925C5.37552 2.44829 4.00002 4.06463 4.00002 6C4.00002 6.62782 3.86566 8.19725 3.54116 9.74216C3.38006 10.5091 3.16454 11.3076 2.87809 12H13.1219C12.8355 11.3076 12.62 10.5091 12.4589 9.74216C12.1344 8.19724 12 6.62782 12 6C12 4.06462 10.6245 2.44827 8.7972 2.07924L8 1.91825ZM14.2193 12C14.4426 12.4474 14.7015 12.801 15 13H1C1.29853 12.801 1.55745 12.4474 1.7807 12C2.67922 10.1994 3.00002 6.87916 3.00002 6C3.00002 3.57934 4.7202 1.56045 7.00484 1.09904C7.00164 1.06646 7 1.03342 7 1C7 0.447715 7.44772 0 8 0C8.55228 0 9 0.447715 9 1C9 1.03342 8.99836 1.06646 8.99516 1.09903C11.2798 1.56042 13 3.57932 13 6C13 6.87916 13.3208 10.1994 14.2193 12Z"
      fill="#001DE0"
    />
  </svg>
);

export const MoreHoriz: FC = () => (
  <svg
    width={30}
    height={30}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21 15.75c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75zm0-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"
      fillRule="nonzero"
    />
  </svg>
);

export const Star: FC = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12.3275L12.635 15.125L11.405 9.8525L15.5 6.305L10.1075 5.8475L8 0.875L5.8925 5.8475L0.5 6.305L4.595 9.8525L3.365 15.125L8 12.3275Z" fill="#8F8B90"/>
    </svg>
);