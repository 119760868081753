import React from "react";
import Moment from "react-moment";
import { default as cx } from "classnames";

import { useTranslation } from "react-i18next";
import {
  ButtonGroup,
  OverlayTrigger,
  Popover,
  Image,
  Accordion,
} from "react-bootstrap";
import { IPaginationProps } from "../Pagination/Pagination";
import Pagination from "../Pagination/Pagination";
import downarrowimg from "../../../assets/images/chevron-down-blk.svg";
import chevronRight from "../../../assets/images/chevRight.svg";
import downarrow from "../../../assets/images/chevron-down.svg";
import edit from "../../../assets/images/editBorder.svg";
import deleteIcon from "../../../assets/images/deleteBorder.svg";
// import suggest from "../../../assets/images/suggest.svg";
// import unSuggest from "../../../assets/images/unSuggest.svg";

import QuestionListSkeleton from "../Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import { convertDataToMarkdownFormat } from "../../../utils/appUtils";
import "./styles.scss";
import Select from "react-select";

const DateComponent = ({ row }: any) => (
  <Accordion.Toggle eventKey={row._id} className="no-btn">
    <div>
      <Moment utc local format="DD/MM/YYYY hh:mm A">
        {row.updated_at}
      </Moment>
    </div>
  </Accordion.Toggle>
);
const PopoverComponent = (data: any) => {
  return (
    <Popover id="popover-basic-" {...data.popoverComponentProps}>
      <Popover.Content
        dangerouslySetInnerHTML={{
          __html:
            data.row.responses && data.row.responses.length > 0
              ? convertDataToMarkdownFormat(data.row.responses[0].text)
              : "",
        }}
      ></Popover.Content>
    </Popover>
  );
};

export interface IntentDataTableProps {
  onExportTableClick: (e: any) => void;
  columns?: [];
  data?: [];
  search?: {
    placeHolder?: string;
    handleInput: (e: any) => void;
    value?: string;
  };
  selectedButtons?: React.ReactElement[];
  pagingProps?: IPaginationProps;
  editRow: (intent: any) => void;
  deleteRow: (id: string[], isMultipleDelete?: boolean) => void;
  suggestRow: (e: any, selectedRow: string, suggested: boolean) => void;
  topics?: [];
  handleTopicChange?: (e: any) => void;
  selectedTopic?: any;
  selectedSort?: any;
  setSelectedSort?: (e: any) => void;
  selectedRows?: string[];
  setSelectedRows?: (ids: string[]) => void;
  progressPending?: boolean;
  paginationPerPage: number;
  BulkAssignCategoryhandler: (e: boolean) => void;
  suggestBulkKnowledgeHandler: (e: any, suggestedRows: string[]) => void;
}

export const ChartTableSortIcon = (data: any) => {
  const { t } = useTranslation("translation");
  return (
    <img
      src={downarrowimg}
      style={{ position: "absolute", right: "10%" }}
      alt={t("collapsed")}
      className="chart-table-sort-icon"
    />
  );
};

export const conditionalRowStyles = [
  {
    when: (row: any) => row.is_suggested === true,
    style: {
      borderLeft: "3px solid rgba(255, 0, 158, 0.7)",
    },
  },
];

const IntentDataTable: React.FC<IntentDataTableProps> = ({
  onExportTableClick,
  search,
  data,
  columns,
  progressPending,
  pagingProps,
  editRow,
  deleteRow,
  suggestRow,
  handleTopicChange,
  topics,
  selectedTopic,
  selectedSort,
  setSelectedSort,
  setSelectedRows,
  selectedRows,
  paginationPerPage,
  BulkAssignCategoryhandler,
  suggestBulkKnowledgeHandler,
}: IntentDataTableProps) => {
  const { t } = useTranslation("translation");
  const {
    pagination: paginationProps,
    onChangePage: handlePagingChange,
    showScrollButtons,
    scrollRightValue,
    currentPage,
    pageChange,
  } = pagingProps;
  const [activeAcc, setActiveAcc] = React.useState<String>("");

  const ActionColumn = ({ row }: any) => (
    <PermissionWrapper>
      <ButtonGroup aria-label="Basic example">
        <Image
          className="action-button"
          role="button"
          src={edit}
          onClick={() => editRow(row)}
        />
        <Image
          className="action-button red"
          role="button"
          src={deleteIcon}
          onClick={() => deleteRow([row._id], false)}
        />
        {/* {row?.is_suggested ? (
          <Image
            className="action-button red"
            role="button"
            src={unSuggest}
            onClick={(e) => suggestRow(e, row._id, !row?.is_suggested)}
          />
        ) : (
          <Image
            className="action-button red"
            role="button"
            src={suggest}
            onClick={(e) => suggestRow(e, row._id, !row?.is_suggested)}
          />
        )} */}
        <Accordion.Toggle eventKey={row._id} className="toggle-btn">
          <Image
            src={chevronRight}
            className={cx({ "chev expanded": activeAcc === row._id })}
          />
        </Accordion.Toggle>
      </ButtonGroup>
    </PermissionWrapper>
  );

  const Question = ({ row }: any) => (
    <Accordion.Toggle eventKey={row._id} className="no-btn">
      <div
        data-tag="allowRowEvents"
        style={{
          color: "#07032B",
          overflow: "hidden",
          textOverflow: "ellipses",
          display: " -webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {row.questions && row.questions.length > 0
          ? row.questions[row.questions.length - 1].text
          : ""}
      </div>
    </Accordion.Toggle>
  );

  const Answer = ({ row }: any) => (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="auto"
      overlay={(d) => <PopoverComponent popoverComponentProps={d} row={row} />}
    >
      <Accordion.Toggle eventKey={row._id} className="no-btn">
        <div
          data-tag="allowRowEvents"
          style={{
            color: "#07032B",
            overflow: "hidden",
            textOverflow: "ellipses",
            display: " -webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            wordBreak: "break-all",
          }}
        >
          {row.responses && row.responses.length > 0
            ? convertDataToMarkdownFormat(row.responses[0].text)
            : ""}
        </div>
      </Accordion.Toggle>
    </OverlayTrigger>
  );

  const Topic = ({ row }: any) => {
    return (
      <Accordion.Toggle eventKey={row._id} className="no-btn">
        <div className="w-100" key={"div_" + row._id}>
          {row?.knowledge_group_3_name}
        </div>
      </Accordion.Toggle>
    );
  };

  const Module = ({ row }: any) => {
    return (
      <Accordion.Toggle eventKey={row._id} className="no-btn">
        <div className="w-100" key={"div_" + row._id}>
          {row?.knowledge_group_2_name}
        </div>
      </Accordion.Toggle>
    );
  };

  const Year = ({ row }: any) => {
    return (
      <Accordion.Toggle eventKey={row._id} className="no-btn">
        <div className="w-100" key={"div_" + row._id}>
          {row?.knowledge_group_1_name}
        </div>
      </Accordion.Toggle>
    );
  };

  const defaultColumns = [
    {
      name: t("Question"),
      selector: "question",
      sortable: true,
      cell: (row: any) => <Question row={row} />,
      grow: 1,
    },
    {
      name: t("Answer"),
      selector: "response",
      sortable: true,
      cell: (row: any) => <Answer row={row} />,
      grow: 1,
    },
    {
      name: t("Years"),
      selector: "knowledge_group_1_name",
      sortable: true,
      cell: (row: any) => <Year row={row} />,
      grow: 0,
      width: "120px",
    },
    {
      name: t("Module"),
      selector: "knowledge_group_2_name",
      sortable: true,
      cell: (row: any) => <Module row={row} />,
      grow: 0,
      width: "150px",
    },
    {
      name: t("Topic"),
      selector: "knowledge_group_3_name",
      sortable: true,
      cell: (row: any) => <Topic row={row} />,
      grow: 0,
      width: "150px",
    },
    {
      name: t("Last Updated"),
      selector: "updated_at",
      sortable: true,
      cell: (row: any) => <DateComponent row={row} />,
      grow: 0,
      width: "100px",
    },
    {
      name: t("Action"),
      selector: "action",
      sortable: false,
      cell: (row: any) => <ActionColumn row={row} />,
      grow: 0,
      width: "220px",
    },
  ];

  const DefaultExpandableComponent = ({ data, allIntents }: any) => {
    const { t } = useTranslation("translation");
    const parent = allIntents.find(
      (intent: any) => intent._id === data.parent_id
    );
    return (
      <div className="pl-5 expand-intent">
        <div className="posible-ques p-2">
          <h3>{t("Possible questions")}</h3>
          <ol className="pb-1 pl-4 m-0">
            {data.questions.length > 0
              ? data.questions.map((q: { text: string }, i: number) => {
                  return (
                    <li key={"q_" + data._id + "_" + i}>
                      <p>{q.text}</p>
                    </li>
                  );
                })
              : "-"}
          </ol>
          <h3>{t("Possible responses")}</h3>
          <ol className="pb-1 pl-4 m-0">
            {data.responses.length > 0
              ? data.responses.map(
                  (
                    response: {
                      text: string;
                      group_ids: any;
                    },
                    i: number
                  ) => {
                    return (
                      <li key={"response_" + data._id + "_" + i}>
                        <p>{convertDataToMarkdownFormat(response.text)}</p>
                      </li>
                    );
                  }
                )
              : "-"}
          </ol>
          <h3> {t("Keywords")}</h3>
          <div className="pb-4">
            {data?.tags.map((t: any) => (
              <span className="badge badge-pill badge-primary">{t}</span>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h3>{t("Author email")}</h3>
              <p>{data?.author_email}</p>
            </div>
            <div className="col-lg-6">
              <h3> {t("Parent Question")}</h3>
              <p>{parent ? parent.name : "-"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (progressPending) {
    return <QuestionListSkeleton className="portal-data-table" />;
  }

  return (
    <div className="portal-data-table">
      <section className="d-flex justify-content-between mb-3">
        {/* search,filter,exportable */}
        <div className="search-filter d-flex align-items-center">
          <div className="col-lg-3 pl-0">
            <Select
              className="select-sort select-target"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={[{ value: "", label: t("All Years") }, ...topics]}
              onChange={handleTopicChange}
              value={selectedTopic}
            />
          </div>

          {search && (
            <input
              type="text"
              className="form-control search-text"
              placeholder={search?.placeHolder}
              onChange={search?.handleInput}
              value={search?.value}
            />
          )}
        </div>
        <div className="ml-auto">
          <button
            className="btn-outline-primary action btn btn-suggest w-auto h-100"
            onClick={onExportTableClick}
          >
            {t("Export table (CSV)")}
          </button>
        </div>
      </section>
      <section className="d-flex p-3">
        <PermissionWrapper>
          <input
            type="checkbox"
            className="mr-2"
            id="select-all"
            checked={data.length === selectedRows.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRows(data.map((d: any) => d._id));
              } else {
                setSelectedRows([]);
              }
            }}
          />
          <label htmlFor="select-all" className="mb-0">
            {t("Select all")}
          </label>
        </PermissionWrapper>
        <div className="d-flex pl-3">
          {selectedRows.length > 0 && (
            <PermissionWrapper>
              {/* <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) => suggestBulkKnowledgeHandler(e, selectedRows)}
              >
                {t("Suggest Selected")}
              </button> */}

              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) => BulkAssignCategoryhandler(true)}
              >
                {t("Move Selected")}
              </button>
              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={() => {
                  deleteRow(selectedRows, true);
                  setSelectedRows([]);
                }}
              >
                {t("Delete Selected")}
              </button>
            </PermissionWrapper>
          )}
        </div>
      </section>
      <section className="portal-data-table-wraper">
        <div>
          <div className="headers">
            {defaultColumns.map((h) => (
              <div
                className={cx(`header-cell grow${h.grow}`, {
                  sorted: selectedSort?.field === h?.selector,
                })}
                style={{ width: h.width, minWidth: h.width }}
                role={`${h?.sortable ? "button" : "document"}`}
                onClick={() => {
                  if (!h.sortable) return;

                  if (selectedSort?.field === h?.selector) {
                    setSelectedSort({
                      field: h.selector,
                      direction:
                        selectedSort?.direction === "dsc" ? "asc" : "dsc",
                    });
                  } else {
                    setSelectedSort({ field: h.selector, direction: "asc" });
                  }
                }}
              >
                {h.name}
                {selectedSort?.field === h.selector && (
                  <div className="mr-3">
                    <Image
                      src={downarrow}
                      style={{ width: "15px" }}
                      className={cx("sort", {
                        asc: selectedSort?.direction === "asc",
                      })}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="body-rows">
            <Accordion defaultActiveKey="0" onSelect={(id) => setActiveAcc(id)}>
              {data.map((r: any) => (
                <div>
                  <div
                    // className={cx("table-row", { suggested: r.is_suggested })}
                    className="table-row"
                  >
                    <PermissionWrapper>
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedRows.indexOf(r._id) >= 0}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedRows([...selectedRows, r._id]);
                          } else {
                            setSelectedRows(
                              [...selectedRows].filter((id) => id !== r._id)
                            );
                          }
                        }}
                      />
                    </PermissionWrapper>

                    {defaultColumns.map((c) => (
                      <div
                        className={`row-cell grow${c.grow}`}
                        style={{ width: c.width, minWidth: c.width }}
                      >
                        {c.cell(r)}
                      </div>
                    ))}
                  </div>
                  <Accordion.Collapse eventKey={r._id}>
                    <DefaultExpandableComponent data={r} allIntents={data} />
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
      {paginationProps && (
        <Pagination
          showScrollButtons={showScrollButtons}
          scrollRightValue={scrollRightValue}
          scrollWrapper={"table-wraper"}
          currentPage={currentPage}
          pagination={paginationProps}
          paginationPerPage={paginationPerPage}
          pageChange={pageChange}
          onChangePage={handlePagingChange}
        />
      )}
    </div>
  );
};

export default IntentDataTable;
