/* eslint-disable jsx-a11y/anchor-is-valid */
import { default as classNames, default as cx } from "classnames";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Dropdown,
  Row,
  Tab,
  Tabs,
  useAccordionToggle,
} from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import emptyKnowledge from "../../../assets/New-images/empty-knowledge.svg";
import sortIcon from "../../../assets/New-images/sort-blue-icon.svg";
import { KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH } from "../../../config";
import { TagsComponent } from "../../../routes/Knowledge/AllKnowledge";
import "../../../routes/Knowledge/AllKnowledge/AllKnowledge.scss";
import UploadedDocuments from "../../../routes/UploadedDocuments/UploadedDocuments";
import {
  deleteKnowledgeById,
  restoreKnowledgeById,
  getAllKnowledge,
  getAllLevels,
  getAllModulesFromYear,
} from "../../../store/knowledge/actions";
import {
  isAllKnowledgeLoading,
  isAllKnowledgeSuccess,
  isTopicsLoading,
} from "../../../store/knowledge/selector";
import { getAllUserGroupOptions } from "../../../store/notifications/actions";
import { getAllIntents } from "../../../store/questions/actions";
import { getAllIntents as getAllIntentsSelector } from "../../../store/questions/selector";
import { convertDataToMarkdownFormat } from "../../../utils/appUtils";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import { TwoButtonModal as KnowledgeConfirmationModal } from "../Common";
import Pagination from "../Pagination/Pagination";

import { useTranslation } from "react-i18next";

function ArchiveKnowledgeDatatableView(props: any) {
  const { t } = useTranslation("translation");
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [scrollRight, setScrollRight] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [topicIds, setTopicIds] = useState("");
  const allKnowledgeSuccess = useSelector(isAllKnowledgeSuccess);
  const allKnowledgeLoading = useSelector(isAllKnowledgeLoading);
  const topicsLoading = useSelector(isTopicsLoading);
  const allIntents = useSelector(getAllIntentsSelector);

  const [activeKey, setActiveKey] = useState(null);
  const [activeTab, setActiveTab] = useState("viewKnowledge");
  const [showDeletePermanentlyModal, setShowDeletePermanentlyModal] =
    useState(false);
  const [deletedRowId, setDeletedRowId] = useState([]);
  const isMgs = process.env.REACT_APP_CISCO === "mgs";

  useEffect(() => {
    if (!topicsLoading) {
      dispatch(getAllIntents());
      dispatch(getAllUserGroupOptions());
    }
    getAllYearsFun();
  }, []);

  useEffect(() => {
    if (isMgs) {
      if (props.onTabChanges) {
        props.onTabChanges("viewDocument");
      }
      setActiveTab("viewDocument");
    }
  }, []);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedParentModule, setSelectedParentModule] = useState<any>();

  useEffect(() => {
    if (modules.length > 0) {
      setSelectedNodes([]);
      setHasTopLevelApplied(false);
      onSelectGroupId(modules[0].value);
      if (modules[0].depth === 0) {
        setSelectedParentModule(modules[0]);
      }
    }
  }, [modules]);

  const isBranchUnderSelectedModule = (level: any) => {
    if (!selectedParentModule) {
      return false;
    }

    const findParent = (module: any) => {
      const parent = modules.find((m) => m._id === module.parent_id);
      return parent;
    };

    let parent = findParent(level);

    while (parent) {
      if (parent._id === selectedParentModule._id) {
        return true;
      }
      parent = findParent(parent);
    }
    return false;
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response]);

      const query = new URLSearchParams(useLocatio.search);
      const parent = query.get("parent");
      if (parent) {
        const parentVal = response.find((par: any) => par._id === parent);
        if (parentVal) {
          getAllModulesFromYearFun(parentVal);
        } else {
          response &&
            response.length > 0 &&
            getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
        }
      } else {
        response &&
          response.length > 0 &&
          getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
      }
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");
      if (group) {
        setSelectedGroupId(group);
      } else {
        setSelectedGroupId(year._id);
      }
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };

  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        dispatch(
          getAllKnowledge(
            3,
            1,
            perPage,
            query,
            topicIds,
            {},
            selectedNodes.length > 0,
            props.documentId,
            selectedYear && selectedYear._id && query && query.length > 0
              ? selectedYear._id
              : selectedGroupId,
            query && query.length > 0 ? true : false,
            true
          )
        );
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };

  const [selectedNodes, setSelectedNodes] = useState([]);

  const deleteConfirmed = async (selectedRowIds: string[]) => {
    await dispatch(deleteKnowledgeById(selectedRowIds, false));
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied,
        true
      )
    );
    setSelectedNodes([]);
  };

  const restoreArchived = async (selectedRowIds: string[]) => {
    await dispatch(restoreKnowledgeById(selectedRowIds));
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied,
        true
      )
    );
    setSelectedNodes([]);
  };

  const onSortHandler = (selector: any, direction: string) => {
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {
          sort_field: selector === "name" ? "question" : selector,
          sort_order: direction === "desc" ? "dsc" : "asc",
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied,
        true
      )
    );
  };

  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasTopLevelApplied, setHasTopLevelApplied] = useState(true);

  useEffect(() => {
    if (activeTab === "viewKnowledge") {
      dispatch(
        getAllKnowledge(
          3,
          currentPage,
          perPage,
          query,
          topicIds,
          {},
          selectedNodes.length > 0,
          props.documentId,
          !props.documentId ? selectedGroupId : null,
          hasTopLevelApplied,
          true
        )
      );
    }
  }, [selectedGroupId, activeTab]);

  const onSelectGroupId = (level: string) => {
    // For now we are allowing server side sorting on above two fields
    setSelectedGroupId(level);
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (
        allKnowledgeSuccess &&
        allKnowledgeSuccess.intents &&
        allKnowledgeSuccess.intents.length > 0
      ) {
        let data: any[] = [
          ...allKnowledgeSuccess.intents.map((intent: any) => intent._id),
        ];
        setSelectedNodes([..._.uniq(data)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    // console.log('in')
    dispatch(
      getAllKnowledge(
        3,
        page,
        newPerPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied,
        true
      )
    );
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    // e.preventDefault();
    // console.log('[[[[[ DISPATCHED ]]]]]', currentPage, page)
    setCurrentPage(page);
    // console.log('in')
    dispatch(
      getAllKnowledge(
        3,
        page,
        perPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied,
        true
      )
    );
  };

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    is_suggested,
    breadcrumbs,
  }: any) => {
    const currentEventKey = useContext(AccordionContext);
    setActiveKey(currentEventKey);
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
      <>
        <input
          type="checkbox"
          className="mr-2"
          onChange={(e) => {
            addToSelectedRows(e, eventKey);
          }}
          checked={selectedNodes.indexOf(eventKey) >= 0}
        />

        <span
          // className={`${is_suggested ? "is_suggested" : ""}`}
          onClick={decoratedOnClick}
        >
          {children}
          <small className="d-block k-breadcrumb">
            {breadcrumbs.map((b: any) => b.name).join("/")}
          </small>
        </span>
      </>
    );
  };

  const getLevelNames = (levels: string[]) => {
    let moduleAsString: string[] = [];
    levels.forEach((level) => {
      const moduleObj = modules.find((m: any) => m.value === level);
      if (moduleObj) {
        moduleAsString.push(moduleObj.label);
      }
    });
    return moduleAsString.length === 0
      ? "Uncategorised"
      : moduleAsString.join(", ");
  };

  const knowledgeHtml = (
    <>
      <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
        <Dropdown className="mr-2 sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 "
            variant="default"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("updated_at", "desc");
              }}
            >
              {t("Most Recent")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "desc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="web-links my-0 mr-0">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search Knowledge Base")}
            onChange={onInputChangeHandler}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="d-flex align-items-center">
          <div className="custom-checkbox mr-3 align-items-center">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
              checked={
                allKnowledgeSuccess &&
                allKnowledgeSuccess.intents &&
                allKnowledgeSuccess.intents.length > 0 &&
                selectedNodes.length === allKnowledgeSuccess.intents.length
              }
              onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              {t("Select all")}
            </label>
          </div>
          {selectedNodes && selectedNodes.length > 0 && (
            <PermissionWrapper>
              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) => restoreArchived(selectedNodes)}
              >
                {t("Restore Selected")}
              </button>

              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={(e) => {
                  setShowDeletePermanentlyModal(true);
                  setDeletedRowId(selectedNodes);
                }}
              >
                {t("Delete Selected")}
              </button>
            </PermissionWrapper>
          )}
        </div>
      </div>
      <Accordion
        defaultActiveKey={
          allKnowledgeSuccess && allKnowledgeSuccess.intents.length > 0
            ? allKnowledgeSuccess.intents[0]._id
            : null
        }
        className="accordion-viewby mt-3 scroll-wrap"
      >
        {allKnowledgeSuccess &&
          allKnowledgeSuccess.intents &&
          allKnowledgeSuccess.intents.map((knowledge: any, index: number) => {
            const parent = allIntents.find(
              (intent: any) => intent._id === knowledge.parent_id
            );
            return (
              <Card
                className={cx({
                  active: activeKey === "0",
                  notactive: activeKey !== "0",
                })}
                key={knowledge?._id}
              >
                <Card.Header>
                  <ContextAwareToggle
                    is_suggested={knowledge.is_suggested}
                    eventKey={knowledge?._id}
                    breadcrumbs={
                      knowledge.breadcrumbs && knowledge.breadcrumbs.length > 0
                        ? knowledge.breadcrumbs
                        : []
                    }
                  >
                    {knowledge.questions && knowledge.questions.length > 0
                      ? knowledge.questions[knowledge.questions.length - 1].text
                      : ""}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={knowledge?._id}>
                  <Card.Body>
                    <div>
                      <h3 className="h6 clr-black mb-1">{t("Answer:")}</h3>
                      {knowledge.responses && knowledge.responses.length > 0
                        ? convertDataToMarkdownFormat(
                            knowledge.responses[0].text
                          )
                        : ""}
                    </div>
                    <Row className="mt-3">
                      <Col md={3} className="my-2 my-md-0">
                        <h3 className="h6 clr-black mb-1">
                          {t("Generated from:")}
                        </h3>
                        <div>{knowledge?.document_name}</div>
                      </Col>
                      <Col md={3} className="my-2 my-md-0">
                        <h3 className="h6 clr-black mb-1">{t("Created by")}</h3>
                        <div>{knowledge?.author_email}</div>
                      </Col>
                      <Col md={3} className="my-2 my-md-0">
                        <h3 className="h6 clr-black mb-1">{t("Level")}</h3>
                        <div>{knowledge?.author_email}</div>
                      </Col>
                      <Col md={3} className="my-2 my-md-0">
                        <h3 className="h6 clr-black mb-1">
                          {t("Last Updated(DD/MM/YYYY hh:mm A)")}
                        </h3>
                        <div>
                          <Moment utc local format="DD/MM/YYYY hh:mm A">
                            {knowledge.updated_at}
                          </Moment>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={4} className="my-2 my-md-0">
                        <h3 className="h6 clr-black mb-1">
                          {t("Level (Module)")}
                        </h3>
                        <div>
                          {getLevelNames(
                            knowledge.knowledge_group_ids
                              ? knowledge.knowledge_group_ids
                              : []
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <h3 className="h6 clr-black mb-1">{t("Keywords")}</h3>
                      <TagsComponent row={knowledge} />
                    </div>
                    <div className="mt-3 d-none">
                      <h3 className="h6 clr-black mb-1">
                        {t("Possible questions")}
                      </h3>
                      <ol>
                        {knowledge.questions.length > 0
                          ? knowledge.questions.map(
                              (q: { text: string }, i: number) => {
                                return (
                                  <li key={"q_" + knowledge._id + "_" + i}>
                                    <p>{q.text}</p>
                                  </li>
                                );
                              }
                            )
                          : "-"}
                      </ol>
                    </div>
                    <div className="mt-3 d-none">
                      <h3 className="h6 clr-black mb-1">
                        {t("Possible responses")}
                      </h3>
                      <ol>
                        {knowledge.responses.length > 0
                          ? knowledge.responses.map(
                              (
                                response: {
                                  text: string;
                                  group_ids: any;
                                },
                                i: number
                              ) => {
                                return (
                                  <li
                                    key={"response_" + knowledge._id + "_" + i}
                                  >
                                    <p>
                                      {convertDataToMarkdownFormat(
                                        response.text
                                      )}
                                    </p>
                                  </li>
                                );
                              }
                            )
                          : "-"}
                      </ol>
                    </div>
                    <div className="mt-3 d-none">
                      <h3 className="h6 clr-black mb-1">
                        {t("Parent question")}
                      </h3>

                      {parent ? parent.name : "-"}
                    </div>
                    <div className="mt-3 text-right">
                      <button
                        className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                        onClick={() => restoreArchived([knowledge._id])}
                      >
                        {t("Restore")}
                      </button>
                      <button
                        className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2"
                        onClick={() => {
                          setShowDeletePermanentlyModal(true);
                          setDeletedRowId([knowledge._id]);
                        }}
                      >
                        {t("Delete")}
                      </button>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
      </Accordion>

      {/* empty state */}
      {allKnowledgeSuccess &&
        allKnowledgeSuccess.intents.length === 0 &&
        !props.manageDocument && (
          <div className="empty-knowledge text-center my-5">
            <h3 className="h6 clr-grey-txt7 mb-3">
              {t("You have yet to create knowledge")}
            </h3>
            <figure className="h-auto">
              <img src={emptyKnowledge} alt="" />
            </figure>
            <div className="clr-primary font-weight-bold">
              {/* Drop a document here <span className="clr-grey-txt7">or</span>{" "} <br /> */}
              <Link
                to={
                  KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                  (!hasTopLevelApplied
                    ? `?group=${selectedGroupId}&parent=${selectedYear._id}`
                    : "")
                }
              >
                {t("Click here to get started")}
              </Link>
            </div>
          </div>
        )}
      {allKnowledgeSuccess &&
        allKnowledgeSuccess.pagination &&
        allKnowledgeSuccess.intents.length > 0 && (
          <Pagination
            showScrollButtons={false}
            scrollRightValue={scrollRight}
            scrollWrapper={"table-wraper"}
            currentPage={currentPage}
            pagination={allKnowledgeSuccess.pagination}
            paginationPerPage={perPage}
            pageChange={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        )}
    </>
  );

  return (
    <>
      <KnowledgeConfirmationModal
        show={showDeletePermanentlyModal}
        title={t("Confirm Delete")}
        message={t(
          "Are you sure you would like to delete? Deleted items would be deleted permanently"
        )}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Cancel")}
        rightButtonClassName="btn btn-outline-primary font-weight-normal mx-2"
        onClickLeftButton={() => {
          setShowDeletePermanentlyModal(false);
          deleteConfirmed(deletedRowId);
        }}
        onClickRightButton={() => {
          setShowDeletePermanentlyModal(false);
        }}
        onClickClose={() => setShowDeletePermanentlyModal(false)}
      />

      {props.manageDocument && (
        <>
          <Row className="mb-4 position-relative manage-document-knowledge">
            <Col lg={12} md={12} className="knowledge-modules mb-4 mb-md-0">
              <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column">
                {!allKnowledgeLoading && knowledgeHtml}
              </div>
            </Col>
          </Row>
        </>
      )}

      {!props.manageDocument && (
        <>
          <div>
            <div className="levels-row mb-4 d-flex align-items-center flex-wrap">
              <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
              {years.map((year: any) => {
                return (
                  <button
                    className={classNames({
                      "btn btn-tabs ": true,
                      active: selectedYear && year._id === selectedYear._id,
                    })}
                    onClick={() => {
                      setHasTopLevelApplied(true);
                      getAllModulesFromYearFun(year);
                    }}
                  >
                    {year?.name}
                  </button>
                );
              })}
            </div>
            <Row className="mb-4 position-relative">
              {selectedYear && selectedYear.group_type !== "system" && (
                <Col lg={3} md={4} className="knowledge-modules mb-4 mb-md-0">
                  <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center px-3 py-2">
                      <h2 className="mb-0 font-1">{t("Modules")}</h2>
                    </div>
                    {modules.length > 0 && (
                      <div className="modules-list flex-grow-1 scroll-wrap">
                        <ul className="list-unstyled">
                          {modules.map((level: any) => {
                            return (
                              <li
                                onClick={() => {
                                  if (level.depth === 0) {
                                    setSelectedParentModule(level);
                                  }
                                }}
                                key={
                                  "child_padding_dev_" +
                                  level.value +
                                  "_" +
                                  Math.floor(Math.random() * 9999)
                                }
                                style={{
                                  paddingLeft: `${
                                    level.depth > 0 ? level.depth * 10 + 10 : 10
                                  }px`,
                                  display: `${
                                    isBranchUnderSelectedModule(level) ||
                                    level.depth === 0
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                                className={classNames({
                                  "clr-grey-txt": level.depth > 0,
                                  active: selectedGroupId === level.value,
                                })}
                              >
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setSelectedNodes([]);
                                    setHasTopLevelApplied(false);
                                    onSelectGroupId(level.value);
                                  }}
                                  className={classNames({
                                    "clr-grey-txt": level.depth > 0,
                                  })}
                                >
                                  {level?.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </Col>
              )}
              <div
                className={classNames({
                  "knowledge-viewby": true,
                  "col-lg-9 col-md-8":
                    selectedYear && selectedYear.group_type !== "system",
                  "col-lg-12 col-md-12":
                    selectedYear && selectedYear.group_type === "system",
                })}
              >
                <div className="knowledge-box px-3 py-2 build-knowbox text-left">
                  <h2 className="mb-0 mr-3 font-1 d-md-inline-block">
                    {t("View by:")}{" "}
                  </h2>
                  <Tabs
                    defaultActiveKey={isMgs ? "viewDocument" : "viewKnowledge"}
                    onSelect={(e: any) => {
                      if (props.onTabChanges) {
                        props.onTabChanges(e);
                      }
                      setActiveTab(e);
                    }}
                  >
                    {!isMgs && (
                      <Tab eventKey="viewKnowledge" title={t("Knowledge")}>
                        {knowledgeHtml}
                      </Tab>
                    )}
                    <Tab eventKey="viewDocument" title="Documents">
                      <UploadedDocuments
                        isArchived={true}
                        activeTab={activeTab}
                        queryString={`?group=${selectedGroupId}&parent=${
                          selectedYear ? selectedYear._id : ""
                        }`}
                        hasTopLevelApplied={hasTopLevelApplied}
                        selectedGroupId={selectedGroupId}
                        isMgs={isMgs}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default ArchiveKnowledgeDatatableView;
