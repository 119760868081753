import { t } from "i18next";
import DashboardService from "../../services/DashboardService";
import { HelperService } from "../../services/helper.service";
import KnowledgeService from "../../services/KnowledgeService";
import QuizService from "../../services/QuizService";
import UploadFilesService from "../../services/UploadService";
import {capitalizeFirstLetter} from "../../utils/appUtils";

type AppDispatchType = any;

const service = new KnowledgeService();
const uploadService = new UploadFilesService();
const helperService = new HelperService();
const dashboardService = new DashboardService();
const quizService = new QuizService();

export const getAllQuizzes =
  (params: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getAllQuizzes(params);
      return Promise.resolve(response.data.data.quizzes);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const createQuizz =
  (params: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.createQuizz(params);
      return Promise.resolve(response.data.data.quiz);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const createQuizButtons =
  (params: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.createQuizButtons(params);
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getQuizAlerts =
  (quizId: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getQuizAlerts(quizId);
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const updateQuizAlerts =
  (quizId: any, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.updateQuizAlerts(quizId, data);
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getAllQuizSubmissions =
  (quizId: any, filtersData: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getAllQuizSubmissions(
        quizId,
        filtersData
      );
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const updateSubmissionsAcknowledge =
  (quizId: any, submissionId: any, data: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.updateSubmissionsAcknowledge(
        quizId,
        submissionId,
        data
      );
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getSubmissionsBySubmissionId =
  (quizId: any, submissionId: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getSubmissionsBySubmissionId(
        quizId,
        submissionId
      );
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getSubmissionsReviewsByReviewId = (reviewId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getSubmissionsReviewsByReviewId(reviewId);
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getQuizFeatures = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizFeatures();
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const connectToCanvas = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.connectToCanvas();
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(
      error.response.status === 400
        ? "Integration with Canvas LMS is not configured for this agent."
        : error.response.data.status.message
    );
    return Promise.reject([]);
  }
};

export const connectToOauth2Lms = (lmsCode: string, requests_new_state: boolean = false) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.connectToOauth2Lms(lmsCode.toLowerCase(), requests_new_state);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(
      error.response.status === 400
        ? `Integration with ${capitalizeFirstLetter(lmsCode)} LMS is not configured for this agent.`
        : error.response.data.status.message
    );
    return Promise.reject([]);
  }
};

export const getLearningSubmissionsReviews =
  (quizId: string, status: string = null, filtersData: any = null) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.getLearningSubmissionsReviews(
        quizId,
        status,
        filtersData
      );
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const updateReviews =
  (reviewId: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await quizService.updateReviews(reviewId);
      return Promise.resolve(response.data.data);
    } catch (error: any) {
      return Promise.reject([]);
    }
  };

export const getIntegrationCurrentCourses = (provider: string = "canvas", isLinked?: boolean) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getIntegrationCourses(provider, isLinked);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getIntegrationCourses = (provider: string = "canvas", integrationId?: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getIntegrationCourses(provider, false, integrationId);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const getAssessmentsBasedOnCourses = (courseId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getAssessmentsBasedOnCourses(courseId);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const submitAssessment = (data: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.submitAssessment(data);
    // helperService.showToaster('')
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const updateQuiz = (quizId: string, data: any, hideToast: boolean = false) => async (dispatch: AppDispatchType) => {
  console.log(quizId, data)
  try {
    const response = await quizService.updateQuiz(quizId, data);
    !hideToast && helperService.showToaster('Quiz saved.', 'success');
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(error.response.data.status.message, 'error');
    return Promise.reject([]);
  }
};

export const deleteQuiz = (quizId: string, isArchive?: boolean) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.deleteQuiz(quizId, isArchive);
    helperService.showToaster('Quiz Deleted.', 'success');
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const restoreQuiz = (quizId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.restoreQuiz(quizId);
    helperService.showToaster('Quiz is restored.', 'success');
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const getLinkedQuestionsBasedOnQuizAndQuestions = (quizId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getLinkedQuestionsBasedOnQuizAndQuestions(quizId);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const getLinkedIntentsBasedOnQuizAndQuestions = (quizId: string, questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getLinkedIntentsBasedOnQuizAndQuestions(quizId, questionId);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const getAllQuestionBank = (params: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getAllQuestionBank(params);
    return Promise.resolve(response.data.data); 
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const getAllQuestionBankNoPaging = (params: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getAllQuestionBank(params);
    return Promise.resolve(response.data.data.quiz_questions); 
  } catch (error: any) {
    return Promise.reject([]);
  }
};

export const deleteQuestionBankById = (questionId: any, isArchive: boolean) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.deleteQuestionBankById( questionId, isArchive );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while deleting question.'))
    return Promise.reject([]);
  }
};


export const restoreQuestion = (questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.restoreQuestion( questionId );
    helperService.showToaster(t('Restored successfully.'), 'success')
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while deleting question.'))
    return Promise.reject([]);
  }
}

export const generateQuestionBank = (data: any, showPromt: boolean = true) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.generateQuestionBank( data );
    if ( showPromt ) {
      helperService.showToaster(t('Updated successfully.'), 'success')
    }
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(error.response.data.status.message ? error.response.data.status.message : t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};

export const generateQuestionBankV2 = (quizId: string, data: any, showPromt: boolean = true) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.generateQuestionBankV2(quizId, data);
    if ( showPromt ) {
      helperService.showToaster(t('Updated successfully.'), 'success')
    }
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(error.response.data.status.message ? error.response.data.status.message : t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};

export const updateQuestionFromQuestionBank = (questionId: string, data: any, showPromt: boolean = true) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.updateQuestionFromQuestionBank( questionId, data );
    if ( showPromt ) {
      helperService.showToaster(t('Updated successfully.'), 'success')
    }
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};

export const getQuizQuestionBankById = (questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizQuestionById( questionId );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};

export const deleteQuizQuestionBankById = (questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.deleteQuizQuestionBankById( questionId );
    helperService.showToaster(t('Question deleted successfully.'), 'success')
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};

export const getQuizQuestionBankGenerateTags = (questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizQuestionBankGenerateTags( questionId );
    // helperService.showToaster(t('Updated successfully.'), 'success')
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};


export const updateKeywordsOfQuestion = (questionId: string, tags: string[]) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.updateKeywordsOfQuestion( questionId, tags );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while generating question.'))
    return Promise.reject([]);
  }
};


export const questionBankLinkDocumentSections = (questionId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.questionBankLinkDocumentSections( questionId );
    // helperService.showToaster(t('Updated successfully.'), 'success')
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while linking question.'))
    return Promise.reject([]);
  }
};

export const updateQuestionBankLinkDocumentSections = (questionId: string, data: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.updateQuestionBankLinkDocumentSections( questionId, data );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while linking question.'))
    return Promise.reject([]);
  }
};

export const getQuizDocumentsContents = (quizId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizDocumentsContents( quizId );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while fetching questions.'))
    return Promise.reject([]);
  }
};

export const quizDocumentQuestions = (payload: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.quizDocumentQuestions( payload );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while fetching questions.'))
    return Promise.reject([]);
  }
};

export const getQuizDocumentTableJobsQuestions = (documentId: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizDocumentTableJobsQuestions( documentId );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while fetching questions.'))
    return Promise.reject([]);
  }
};

export const getQuizDocumentTableJobsByJobId = (documentId: any, jobId: string) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getQuizDocumentTableJobsByJobId(documentId, jobId);
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while fetching questions.'))
    return Promise.reject([]);
  }
};

export const saveQuizQuestionsBasedOnFile = (payload: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.saveQuizQuestionsBasedOnFile( payload );
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while fetching questions.'))
    return Promise.reject([]);
  }
};

export const getMcqDistractors = (payload: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await quizService.getMcqDistractors( payload );
    if ( response?.data?.data && response.data.data.length === 0 ) {
      helperService.showToaster(t('We are unable to generate any more distractors.'), 'success')
    }
    return Promise.resolve(response.data.data);
  } catch (error: any) {
    helperService.showToaster(t('Unable to generate any more distractors.'))
    return Promise.reject([]);
  }
};