import cx from "classnames";
import React, {ReactElement} from "react";
import {Dropdown, Image} from "react-bootstrap";
import Moment from "react-moment";
import dotImg from "../../../assets/images/dot-vertical.svg";
import {useSelector} from "react-redux";
import {getIsSuperAdmin} from "../../../store/common/selector";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import agentLangIcon from "../../../assets/New-images/agent-lang-icon.svg";
import agentCalendarIcon from "../../../assets/New-images/agent-calendar-icon.svg";

import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

interface Props {
  _id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  selectedAgent: string;
  language_code?: string;
  deleteAgentHandler?: (e: any) => void;
  chooseCurrentAgentHandler?: (e: any) => void;
  goToCurrentAgentHandler?: () => void;
  hideOptions?: boolean;
  isMultiAgentFeatureEnabled?: boolean;
}

function ChooseAgentListItem({
                               _id,
                               name,
                               description,
                               created_at,
                               updated_at,
                               selectedAgent,
                               deleteAgentHandler,
                               chooseCurrentAgentHandler,
                               hideOptions,
                               isMultiAgentFeatureEnabled,
                               language_code,
                             }: Props): ReactElement {
  const {t} = useTranslation("translation");
  const IsSuperAdmin = useSelector(getIsSuperAdmin);
  const history = useHistory();

  const dropdownMenu = (
      <Dropdown>
        <Dropdown.Toggle className="p-0" variant="default" id="agent-dropdown">
          <Image src={dotImg}/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {IsSuperAdmin && isMultiAgentFeatureEnabled && (
              <Dropdown.Item
                  className="delete"
                  data-agentid={_id}
                  data-name={name}
                  data-description={description}
                  onClick={deleteAgentHandler}
              >
                {t("Delete")}
              </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
  );
  return (
      <div className="col-md-4 col-sm-6 col-xl-3 mb-3">
        <div
            className={cx({
              "knowledge-box position-relative switch-agents-box": true,
              active: _id === selectedAgent,
              "p-0 m-0 px-3 py-3 d-flex flex-column": true
            })}
        >
          <div className="mb-24 d-flex justify-content-between align-items-center">
            <h2 className="mb-0">{name}</h2>
            {deleteAgentHandler && (
                <PermissionWrapper>{!hideOptions && dropdownMenu}</PermissionWrapper>
            )}
          </div>
          <div style={{
            minHeight: 200
          }}>
            <div className="d-flex align-items-center mb-2">
              <div className="d-flex align-items-center mr-3 text-nowrap">
                <img src={agentLangIcon} alt="language" className="mr-1"/>
                <span className="font-12 clr-grey-txt7">
                {language_code ? language_code.toLocaleUpperCase() : ""}
              </span>
              </div>
              <div className="d-flex align-items-center text-nowrap">
                <img src={agentCalendarIcon} alt="calendar" className="mr-1"/>
                <span className="font-12 clr-grey-txt7">
                <Moment date={updated_at} format="DD-MM-YYYY hh:mm A">
                  {updated_at}
                </Moment>
              </span>
              </div>
            </div>
            <p className="mb-4">{description}</p>
            <p>
              {t("Created:")}{" "}
              <Moment date={created_at} format="DD-MM-YYYY hh:mm A">
                {created_at}
              </Moment>
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-auto">

            {_id === selectedAgent && (
                <button disabled className="btn btn-primary clr-blue px-3 py-1 p-0 m-0">{t("Selected")}</button>
            )}
            {_id === selectedAgent && (
                <button
                    className="btn btn-link ml-3"
                    onClick={() => history.push("/")}
                >
                  {t("Go to")}
                </button>
            )}
            {_id !== selectedAgent && (
                <button
                    className="btn btn-primary px-3 py-1 p-0 m-0"
                    onClick={chooseCurrentAgentHandler}
                    data-agentid={_id}
                    data-name={name}
                >
                  {t("Select")}
                </button>
            )}
          </div>
        </div>
      </div>
  );
}

export default ChooseAgentListItem;
