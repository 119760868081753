import toast from "react-hot-toast";
import closeImg from "../assets/images/close-blk.svg";
import notifyBell from "../assets/images/notify-bell.svg";
import triangle from "../assets/images/triangle.svg";
import { Image } from "react-bootstrap";
import * as _ from "lodash";
import greenCheck from "../assets/New-images/green-check.svg";
import redCross from "../assets/New-images/file-size-exceeds-cross.svg";

export class HelperService {
  /**
   *
   *
   * @param {string} message
   * @memberof HelperService
   */
  showToaster(message: string, type: any = null) {
    toast(<span className="font-600 font-16">{message}</span>, {
      style: {
        background:
          type === "info"
            ? "#FFEEBA"
            : type === "success"
            ? "#D4EDDA"
            : type === "error"
            ? "#FFE6F1"
            : // "#00864D"
              "#B00020",
        color: type === "success" || type === "error" ? "black" : "white",
        maxWidth: "640px",
      },
      icon:
        type === "success" ? (
          <Image className="w-16" src={greenCheck} />
        ) : type === "error" ? (
          <Image className="w-16" src={redCross} />
        ) : null,
    });
  }

  showToasterIcon(message: string, type: any = null, icon: any = null) {
    toast(<span>{message}</span>, {
      style: {
        background:
          type === "info"
            ? "#FFEEBA"
            : type === "success"
            ? "#D4EDDA"
            : "#B00020",
        color: "black",
      },
      icon: icon ? <Image src={icon} /> : null,
    });
  }

  showClickableToaster(message: string, onClick: any) {
    toast.success(
      <span role="button" onClick={onClick}>
        {message}
      </span>,
      {
        style: {
          background: "#D4EDDA",
          color: "black",
          borderRadius: "8px",
          maxWidth: "unset",
        },
        iconTheme: {
          primary: "#00864D",
          secondary: "#fff",
        },
        duration: 5 * 1000,
      }
    );
  }

  showCombineToast(message: string, onClick: any) {
    toast(
      <span role="button" onClick={onClick}>
        {message}
      </span>,
      {
        style: {
          background: "#FFF3CD",
          color: "black",
          borderRadius: "8px",
          maxWidth: "unset",
        },
        icon: <Image src={triangle} />,
        duration: 5 * 1000,
      }
    );
  }

  /**
   *
   *
   * @param {string} message
   * @memberof HelperService
   */
  showNotificationToaster(message: string, type: any = null) {
    toast(
      (t: any) => (
        <div className="notify-toaster">
          <img src={notifyBell} alt="Notification" />
          <h2>{message}</h2>
          <p>
            Notification description whatever fits in two lines followed by
            ellipsis…
          </p>
          <button className="btn" onClick={() => toast.dismiss(t.id)}>
            <img src={closeImg} alt="CloseImage" />
          </button>
        </div>
      ),
      {
        position: "top-right",
        duration: 5000,
        style: {
          padding: "0",
          boxShadow: "none",
          borderRadius: "0px",
          background: "transparent",
          // marginTop: '60px',
          maxWidth: "inherit",
        },
      }
    );
  }

  /**
   *
   *
   * @param {*} bytes
   * @returns
   * @memberof HelperService
   */
  formatBytes(bytes: number) {
    const marker = 1024; // Change to 1000 if required
    const decimal = 3; // Change as required
    const kiloBytes = marker; // One Kilobyte is 1024 bytes
    const megaBytes = marker * marker; // One MB is 1024 KB
    const gigaBytes = marker * marker * marker; // One GB is 1024 MB
    const teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if (bytes < megaBytes)
      return (bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if (bytes < gigaBytes)
      return (bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return (bytes / gigaBytes).toFixed(decimal) + " GB";
  }

  /**
   * Generic pagination function to use array pagination
   * @param {*} items
   * @param {*} current_page
   * @param {*} per_page_items
   * @returns
   * @memberof HelperService
   */
  paginator(items: any[], current_page: number, per_page_items: number) {
    let page = current_page || 1,
      per_page = per_page_items || 10,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }

  /**
   * Function is used to generate and download csv file
   * @param {string} data
   * @param {string} name
   * @memberof HelperService
   */
  ConvertToCSV(data: string, name: string) {
    const blob = new Blob([data], { type: "text/csv" });
    const dwldLink = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", name + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  /**
   * Generic function used to get object on the basis of refrence
   * @param {any[]} array
   * @param {*} compareWith
   * @param {*} findByValue
   * @returns
   * @memberof HelperService
   */
  findValueFromObject(
    array: any[],
    compareWith: any,
    findByValue: any,
    returnValue: string
  ) {
    const obj = array.find(
      (element: any) => element[findByValue] === compareWith
    );
    if (obj) {
      return returnValue ? obj[returnValue] : obj;
    }
    return null;
  }
}

export const getAllFormattedSubmissions = (row: any) => {
  let data: any = [];
  data = row.quiz.questions.map((question: any, index: number) => {
    // console.log(' [ getFormattedUserAnswer ]', getFormattedUserAnswer({
    //   parent: row
    // }, index))
    return {
      ...question,
      // answer: getFormattedUserAnswer({
      //   parent: row
      // }, index),
      parent: {
        agent_id: row.agent_id,
        answers: row.answers,
        author_email: row.author_email,
        author_id: row.author_id,
        channel: row.channel,
        chat_session_id: row.chat_session_id,
        connection_id: row.connection_id,
        correct_answer_count: row.correct_answer_count,
        created_at: row.created_at,
        feedback: row.feedback,
        is_acknowledged: row.is_acknowledged,
        quiz: row.quiz,
        quiz_score: row.quiz_score,
        score: row.score,
        updated_at: row.updated_at,
        _id: row._id,
      },
    };
  });

  data.map((question: any, index: number) => {
    question.answer = getFormattedUserAnswer(question, index);
    question.formattedUserScore = getFormattedUserScore(question, index);
    question.recommendedAnswer = getFormattedRecommendedAnswer(question, index);
  });

  const updatedData: any = [...data];
  const mainData: any = [];
  updatedData.map((question: any, index: number) => {
    const hasParent = updatedData.filter(
      (parent: any) => parent.parent_id === question.id
    );
    mainData.push(question);
    if (hasParent && hasParent.length) {
      hasParent.forEach((ques: any) => {
        mainData.push(ques);
      });
    }
  });

  return _.uniqBy(mainData, "id");
};

export const getFormattedRecommendedAnswer = (
  row: any,
  questionIndex: number
) => {
  if (row.type === "radio") {
    const isCorrect = row.options.find((o: any) => o.is_correct);
    return isCorrect ? isCorrect.text : "";
  } else if (row.type === "bullet" || row.type === "text") {
    return row.ideal_answer
      ? row.ideal_answer.replace(/(?:\r\n|\r|\n)/g, "<br />")
      : "";
  } else if (row.type === "checkbox") {
    let html = "";
    row.options
      .filter((opt: any) => opt.is_correct)
      .forEach((option: any) => {
        html += option.text + "<br />";
      });
    return html;
  } else if (row.type === "table") {
    let html = "";
    let temp =
      typeof row.ideal_answer === "string"
        ? jsonParse(row.ideal_answer)
        : row.ideal_answer;
    // if (answerKeySettings && answerKeySettings.enable_column_header) {
    //   temp &&
    //     temp.map((row, index) =>
    //       row.unshift(answerKeySettings.column_headers[index])
    //     )
    // }
    html += "<table class='quiz-table-answer-table'><thead><tr>";
    row.answer_key_settings &&
      row.answer_key_settings.headers &&
      row.answer_key_settings.headers.map(
        (column: any) =>
          (html += `<th class='quiz-table-answer-cell'>${column}</th>`)
      );
    html += "</tr></thead>";
    html += "<tbody>";
    temp &&
      temp.map((columns: any) => {
        html += "<tr>";
        columns.forEach((column: any) => {
          html += `<td class='quiz-table-answer-cell'>${column}</td>`;
        });
        html += "</tr>";
      });
    html += "</tbody></table>";
    return html;
  } else if (row.type === "essay") {
    // return row.parent?.answers[questionIndex]?.feedback
    //   ? row.parent?.answers[questionIndex]?.feedback.replace(
    //       /(?:\r\n|\r|\n)/g,
    //       "<br />"
    //     )
    //   : "";

    const answers = row.parent?.answers[questionIndex];
    const feedback = answers?.feedback
        ? answers.feedback.replace(/(?:\r\n|\r|\n)/g, "<br />")
        : "";

    const itemFeedbacks = answers?.item_feedbacks?.map((fb:string) => fb.replace(/(?:\r\n|\r|\n)/g, "<br />")) || [];
    
    const combinedFeedbacks = [...itemFeedbacks, feedback].filter(fb => fb);

    return combinedFeedbacks.join("<br /><br />"); 
  }
  return "";
};

const getBulletNewDisplayString = (data: any) => {
  let displayStr = "<ul>";
  displayStr += data
    .map((item: any) => {
      return `<li>${item}</li>`;
    })
    .join("");
  displayStr += "</ul>";
  return displayStr;
};

const jsonParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e: any) {
    return false;
  }
};

const getMatchDisplay = (data: any, answer_key_settings: any) => {
  let html = "";
  html += '<div className="display-description">';
  html += '<table className="quiz-table-answer-table">';
  html += "<thead>";
  html += "<tr>";
  if (answer_key_settings && answer_key_settings.headers) {
    answer_key_settings.headers.map(
      (column: any) =>
        (html += `<th className="quiz-table-answer-cell">${column}</th>`)
    );
  }

  html += "</tr>";
  html += "</thead>";
  html += "<tbody>";
  data.map((row: any) => {
    html += "<tr>";
    html += `<td className="quiz-table-answer-cell">${row.term}</td>`;
    html += `<td className="quiz-table-answer-cell">${row.definition}</td>`;
    html += "</tr>";
  });
  html += "</tbody>";
  html += "</table>";
  html += "</div>";
  return html;
};

export const getFormattedUserAnswer = (row: any, questionIndex: number) => {
  try {
    // if (questionIndex === 4) {
    //   console.log('questionIndex', questionIndex, row.parent)
    // }
    let modifiedAnswer = "";
    if (row.type === "radio") {
      const isCorrect = row.parent.answers[questionIndex];
      if (
        typeof isCorrect !== "undefined" &&
        isCorrect &&
        isCorrect.options &&
        isCorrect.options.length > 0
      ) {
        isCorrect.options.forEach((userAnswer: number) => {
          const answer = row.options[userAnswer];
          modifiedAnswer = answer ? answer.text : "";
        });
        return modifiedAnswer;
      }
    } else if (row.type === "bullet" || row.type === "text") {
      const isCorrect = row.parent.answers[questionIndex];
      if (isCorrect) {
        return isCorrect.text
          ? isCorrect.text.replace(/(?:\r\n|\r|\n)/g, "<br />")
          : "";
      }
    } else if (row.type === "newbullet") {
      const isCorrect = row.parent.answers[questionIndex];
      if (isCorrect && isCorrect.text) {
        const trimmedArray = isCorrect.text
          .split("\n")
          .map((item: string) => item.replace("- ", ""));
        return getBulletNewDisplayString(trimmedArray);
      }
      return "";
    } else if (row.type === "checkbox") {
      let html = "";
      const isCorrect = row.parent.answers[questionIndex];
      if (
        typeof isCorrect !== "undefined" &&
        isCorrect &&
        isCorrect.options &&
        isCorrect.options.length > 0
      ) {
        isCorrect.options.forEach((userAnswer: number) => {
          const answer = row.options[userAnswer];
          if (answer) {
            html += answer.text + "<br />";
          }
        });
      }
      return html;
    } else if (row.type === "table" || row.type === "description") {
      const data = row.parent.answers[questionIndex];
      let html = "";
      let temp =
        typeof data === "undefined" || data.text.length === 0
          ? []
          : typeof data.text === "string"
          ? jsonParse(data.text)
          : data.text;
      // if (answerKeySettings && answerKeySettings.enable_column_header) {
      //   temp &&
      //     temp.map((row, index) =>
      //       row.unshift(answerKeySettings.column_headers[index])
      //     )
      // }

      html += "<table class='quiz-table-answer-table'><thead><tr>";
      row.type !== "description" &&
        row.answer_key_settings &&
        row.answer_key_settings.headers &&
        row.answer_key_settings.headers.map(
          (column: any) =>
            (html += `<th class='quiz-table-answer-cell'>${column}</th>`)
        );
      html += "</tr></thead>";
      html += "<tbody>";

      let divideArray = (data: any, controlColumnCount: any) => {
        let temps = [...data];
        if (temps.length > 0) {
          const result = new Array(Math.ceil(temps.length / controlColumnCount))
            .fill(null)
            .map((_) => temps.splice(0, controlColumnCount));
          return result;
        }

        return [];
      };

      let dividedArray = divideArray(
        temp,
        row.answer_key_settings?.column_count *
          row.answer_key_settings?.row_count
      );
      if (dividedArray.length) {
        dividedArray[0].map((columns: any, index: number) => {
          html += "<tr>";
          if (
            "column_headers" in row.answer_key_settings &&
            row.type !== "description"
          ) {
            html += `<th className="quiz-table-header-cell">${row.answer_key_settings?.column_headers?.[index]}</th>`;
          }
          columns.forEach((column: any) => {
            html += `<td class='quiz-table-answer-cell'>${column}</td>`;
          });
          html += "</tr>";
        });
      }
      html += "</tbody></table>";
      return html;
    } else if (row.type === "matching") {
      const isCorrect = row.parent.answers[questionIndex];
      if (isCorrect && isCorrect.matches) {
        return getMatchDisplay(isCorrect.matches, row.answer_key_settings);
      }
      return "";
    } else if (row.type === "essay") {
      return row.parent?.answers[questionIndex]?.text
        ? row.parent?.answers[questionIndex]?.text.replace(
            /(?:\r\n|\r|\n)/g,
            "<br />"
          )
        : "";
    }
    return "";
  } catch (error: any) {
    return "";
  }
};

export const getFormattedUserScore = (row: any, index: number) => {
  const answer = row.parent.answers[index];
  if (answer) {
    // console.log('[answer]', answer)
    // return answer.score ? answer.score : 'Not scored'
    if (row.type === "radio" || row.type === "checkbox") {
      return answer.score;
    } else if (
      row.type === "bullet" ||
      row.type === "text" ||
      row.type === "table" ||
      row.type === "matching" ||
      row.type === "description" ||
      row.type === "newbullet" ||
      row.type === "essay"
    ) {
      return answer.hasOwnProperty("score") ? answer.score : "Not scored";
    }
  }
  return "0";
};

export const copyToClipboar = (text: string) => {
  navigator.clipboard.writeText("text to be copied");
};
