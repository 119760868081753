import React from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import PlanCheck from "../../assets/images/plan-check.svg";
import "./styles.scss";

export const PlanSection = ({
                              isRecommened: isRecommended = false,
                              name,
                              des,
                              priceMonth,
                              btnText,
                              isCurrent,
                              planIncludes,
                              url,
                            }: any) => {
  const {t} = useTranslation("translation");

  return (
      <div
          className={"tw-h-full " + cx("plan-section-wrapper w-100", {selected: isRecommended})}
      >
        {isRecommended && <span className="recommened"> {t("Recommended")} </span>}
        <div className="name">{name}</div>
        <div className="sub">{des}</div>
        <section className="price">
          <div className="month">{priceMonth}</div>
        </section>
        {isCurrent ? (
            <div>
              <a href={url} rel="noopener noreferrer">
                <button
                    className={cx("btn btn-primary p-2 w-100")}
                    disabled={isCurrent}
                >
                  {t("Current Plan")}
                </button>
              </a>
            </div>
        ) : (
            btnText && (
                <a href={url} rel="noopener noreferrer">
                  <button
                      className={cx("btn btn-primary p-2 w-100")}
                      disabled={isCurrent}
                  >
                    {btnText}
                  </button>
                </a>
            )
        )}

        <section>
          <div className="feature-label tw-mb-4">{t("Plan Includes")}</div>
          <div>
            {planIncludes.map((p: any) => (
                <div className="feature-item d-flex">
                  <img src={PlanCheck} alt=""/>
                  <span className="feature"> {p}</span>
                </div>
            ))}
          </div>
        </section>
      </div>
  );
};

export default PlanSection;
