import React, {useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import learningOutcomesImg from "../../../assets/New-images/learning-outcomes-img.svg";
import summariseDocImg from "../../../assets/New-images/summarise-doc-img.svg";
import contextialiseChatImg from "../../../assets/New-images/contextualised-chat-img.svg";
import genQueAnsImg from "../../../assets/New-images/gen-que-ans-img.svg";
import mangeKeyPhrase from "../../../assets/New-images/manage-key-phrase.svg";
import manageConversationFlow from "../../../assets/New-images/manage-conversation-flow.svg";
import {useTranslation} from "react-i18next";
import SelectModalModule from "../../../routes/TeachersToolkit/popups/selectModalModule";
import {useHistory, useLocation} from "react-router";
import {
  CONVERSATION_FLOW,
  KNOWLEDGE_KEYPHRASES,
  TOOLKIT_GENERATE_LEARNING_OUTCOMES,
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
  TOOLKIT_SETUP_CONTEXTUALISED_CHAT,
  TOOLKIT_SUMMARISE_DOCUMENTS,
} from "../../../config";

interface CarouselItem {
  component: React.ReactNode;
}

function KnowledgeCrousel(): JSX.Element {
  const [index, setIndex] = useState<number>(0);
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(3);
  const history = useHistory();
  const location = useLocation();
  const [subHeader, setSubHeader] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  const [showSelectModule, setShowSelectModule] = useState(false);

  const {t} = useTranslation("translation");
  const [header, setHeader] = useState("");

  const CAROUSEL_DATA = [
    {
      name: t("Set Up Contextualised Chat"),
      description: t("Upload your content to serve as sources for your AI assistant’s responses"),
      icon: contextialiseChatImg,
      route: `/knowledge/setup/chat`
    },
    {
      name: t("Generate Q&As"),
      description: t("Extract essential points from your content and create Q&A flashcards"),
      icon: genQueAnsImg,
      route: `/knowledge/setup/generate-qa`
    },
    {
      name: t("Set Learning Outcomes"),
      description: t("Align generated content by setting learning outcomes"),
      icon: learningOutcomesImg,
      route: `/knowledge/setup/learning-outcomes`
    },
    {
      name: t("Summarise Document(s)"),
      description: t("Create contents by summarising or merging existing documents"),
      icon: summariseDocImg,
      route: `/knowledge/setup/summarise-doc`
    },
    {
      name: t("Manage Key Phrases"),
      description: t("Define and link key phrases to improve your contextual responses"),
      icon: mangeKeyPhrase,
      route: KNOWLEDGE_KEYPHRASES
    },
    {
      name: t("Manage Conversation Flow"),
      description: t("Set up and connect content for a tailored chat experience"),
      icon: manageConversationFlow,
      route: CONVERSATION_FLOW
    }
  ];

  const confirmCategoryUpdate = async (
      selectedOption: any,
      createButtons: boolean = false,
      key: string
  ) => {
    if (selectedOption) {
      setShowSelectModule(false);
      setHeader("");
      setSubHeader("");
      setSelectedFlow("");
      switch (key) {
        case "CONTEXTUALISED_CHAT":
          history.push(
              `${TOOLKIT_SETUP_CONTEXTUALISED_CHAT}?group=${selectedOption}`
          );
          break;
        case "LEARNING_OUTCOMES":
          history.push(
              `${TOOLKIT_GENERATE_LEARNING_OUTCOMES}?group=${selectedOption}`
          );
          break;
        case "GENERATE_KNOWLEDGE":
          history.push(
              `${TOOLKIT_GENERATE_QNA_UPLOAD_DOC}?group=${selectedOption}`
          );
          break;
        case "SUMMARISE_DOCUMENTS":
          history.push(
              `${TOOLKIT_SUMMARISE_DOCUMENTS}?group=${selectedOption}`
          );
          break;

        default:
          break;
      }
    }
  };

  const handleSelect = (selectedIndex: number): void => {
    if (selectedIndex < totalSlides) {
      setIndex(selectedIndex);
    } else {
      setIndex(totalSlides - 1);
    }
  };

  const items: CarouselItem[] = CAROUSEL_DATA.map((item) => {
    return {
      component: (
          <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
            <img src={item.icon} alt=""/>
            <div
                className="pl-3"
                onClick={() => {
                  history.push(item.route);
                }}
            >
              {/*<h2 className="clr-grey-txt7 h6 mb-1">{item.description}</h2>*/}
              <h2 className="mb-0 clr-primary">
                {item.name}{" "}
              </h2>
              <span className="description clr-grey-txt7">{item.description}</span>
            </div>
          </div>
      )
    }
  });

  const totalSlides = Math.ceil(items.length / itemsPerSlide);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setItemsPerSlide(1);
      } else if (window.innerWidth < 992) {
        setItemsPerSlide(2);
      } else {
        setItemsPerSlide(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getItemsForSlide = (slideIndex: number) => {
    if (slideIndex === totalSlides - 1) {
      // For the last slide, always show the last 'itemsPerSlide' items
      return items.slice(-itemsPerSlide);
    } else {
      // For other slides, use the normal slice
      return items.slice(slideIndex * itemsPerSlide, (slideIndex + 1) * itemsPerSlide);
    }
  };

  return (
      <>
        <div className='knowledge-carousel-container'>
          <SelectModalModule
              show={showSelectModule}
              onHide={() => {
                setShowSelectModule(false);
                setHeader("");
                setSubHeader("");
                setSelectedFlow("");
              }}
              confirmCategoryUpdate={confirmCategoryUpdate}
              header={header}
              subHeader={subHeader}
              isBuildKnowledge
              selectedFlow={selectedFlow}
          />
          <Carousel activeIndex={index} onSelect={handleSelect} interval={null} wrap={false}>
            {Array.from({length: Math.ceil(items.length / itemsPerSlide)}).map((_, slideIndex) => (
                <Carousel.Item key={slideIndex}>
                  <div className="multi-item-slide">
                    {getItemsForSlide(slideIndex).map((item, itemIndex) => (
                        <div key={itemIndex} className="carousel-item-wrapper">
                          {item.component}
                        </div>
                    ))}
                  </div>
                </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </>
  );
}

export default KnowledgeCrousel;
