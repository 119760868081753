import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import ReactSelect from "react-select";
import cx from "classnames";
import Loading from "../../components/UI/Loading/Loading";
import {
  Image,
  Modal,
} from "react-bootstrap";
import noodleLogo from "./../../assets/images/noodle-text.svg";
import backArrow from "./../../assets/images/arrow-back.svg";
import tooltipImg from "./../../assets/images/pink-tooltip.svg";

import CommonService from "../../services/CommonService";
import history from "../../utils/history";
import "./styles.scss";
import {LANGUAGE_OPTIONS} from "../../components/Header/ChangeLanguage";
import {COUNTRY_OPTIONS} from "./Constants";
import TwoButtonModal from "../../components/UI/Common/TwoButtonModal";
import ChatImages from "./ChatImages";
import InfoIconDetails from "../../components/UI/Dashboard/InfoIconDetails/InfoIconDetails";
import classNames from "classnames";

import {t} from "i18next";

const windowObj: any = window;

const fields = {
  first_name: {
    field: "first_name",
    label: "First Name",
  },
  last_name: {
    field: "last_name",
    label: "Last Name",
  },
  email: {
    field: "email",
    label: "Email",
    isDisabled: true,
  },
  password: {
    field: "password",
    label: "Password",
    type: "password",
  },
  rePassword: {
    field: "rePassword",
    label: "Confirm Password",
    type: "password",
  },
  name: {
    field: "name",
    label: "Organisation",
  },
  country: {
    field: "country",
    label: "Country",
  },
  lang: {
    field: "lang",
    label: "Language",
  },
};


const commonService = new CommonService();

export const plans = [
  {
    value: windowObj.REACT_APP_PLAN_PROD_STARTER || "5f4c20049bf51d38f9a84519",
    label: "Starter Plan- USD$9.90 Monthly",
  },
];

const SelectLanguageTooltipPrompt = (props: any) => {
  return (
      <Modal
          show={props.show}
          onHide={props.onHide}
          size={"lg"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName={"modal"}
      >
        <Modal.Header className="py-3 modal-header border-bottom" closeButton>
          <Modal.Title
              id="contained-modal-title-vcenter"
              className={"font-16 font-weight-bold"}
          >
            {t("Select Language")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={""}>
            {t(
                "This would be the default language of your chatbot. You can change it later."
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <button
              className={"btn btn-primary font-600 py-1"}
              onClick={props.onHide}
          >
            {t("Close")}
          </button>
        </Modal.Footer>
      </Modal>
  );
};

export const Info = (props: any) => {
  const {t} = useTranslation("translation");
  const [isVerified, setIsVerified] = useState(false);
  const [showPwGuide, setShowPwGuide] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isShowTooltipModal, setIsShowTooltipModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isAws, setIsAws] = useState(false);
  const {form, back, isMobile} = props;
  const query = new URLSearchParams(history.location.search);
  const redUrl = query.get("next_url")
      ? query.get("next_url")
      : windowObj.NEW_PORTAL_URI;

  let source = "web";

  if (history.location && history.location.search) {
    const query = new URLSearchParams(history.location.search);
    source = query.get("source") ? query.get("source") : source;
  }

  useEffect(() => {
    setApiError("");
    setIsAws(
        history.location &&
        history.location.pathname &&
        history.location.pathname.includes("aws-signup")
            ? true
            : false
    );
  }, []);

  const getLanguage = async () => {
    const res = await commonService.getRecommendedLanguage();
    if (res?.data?.data?.country) {
      const country = COUNTRY_OPTIONS.find(
          (c: any) => c.value === res?.data?.data?.country
      );
      if (country) {
        form.setFieldValue("country", country.value);
      }
    }
  };

  useEffect(() => {
    getLanguage();
  }, []);

  const renderInput = ({field, label, isDisabled, type}: any) => {
    if (field === "country") {
      const value = COUNTRY_OPTIONS.find((c: any) => c.value === form.values[field]);
      return (
          <>
            <div className="py-2">{t(label)}</div>
            <ReactSelect
                isClearable={false}
                className="add-tag-select"
                onChange={(c) => form.setFieldValue(field, c.value)}
                defaultValue={value}
                options={COUNTRY_OPTIONS}
                placeholder={t("Country")}
                value={COUNTRY_OPTIONS.find((c) => c.value === form.values[field])}
            />
          </>
      );
    } else if (field === "lang") {
      return (
          <>
            <div className="py-2 d-flex">
              {t(label)}*
              {!isMobile && (
                  <InfoIconDetails
                      isPinkImage
                      placement="right"
                      class="agent-popover"
                      message={t(
                          "This would be the default language of your chatbot. You can change it later."
                      )}
                  />
              )}
              {isMobile && (
                  <div className="ml-2">
                    <Image
                        src={tooltipImg}
                        onClick={() => setIsShowTooltipModal(true)}
                    />
                  </div>
              )}
            </div>

            <ReactSelect
                isSearchable={false}
                isClearable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Change Language"
                options={LANGUAGE_OPTIONS}
                defaultValue={LANGUAGE_OPTIONS.find((l) => l.value === form.values[field])}
                value={LANGUAGE_OPTIONS.find((l) => l.value === form.values[field])}
                onChange={(l: any) => {
                  form.setFieldValue(field, l.value);
                }}
            />
          </>
      );
    }

    return (
        <>
          <div className="py-2">{t(label)}*</div>
          <input
              disabled={isDisabled}
              type={type === "password" ? "password" : "text"}
              value={form.values[field]}
              className={classNames({
                "form-control h-auto w-100 pt-2": true,
                "border-danger": form.errors[field],
              })}
              onChange={(e: any) => {
                form.setFieldValue(field, e?.target?.value);
              }}
              onFocus={() => field === "password" && setShowPwGuide(true)}
              onBlur={() => field === "password" && setShowPwGuide(false)}
          />
          <div className="p-1 clr-red">{t(form.errors[field])}</div>
        </>
    );
  };

  const register = async () => {
    setApiError("");
    setLoading(true);
    try {
      const {
        email,
        country,
        first_name,
        last_name,
        name,
        password,
        product_definition_id,
        lang,
        subscription_id
      } = form.values;
      const res = await commonService.register({
        email,
        country,
        first_name,
        last_name,
        name,
        password,
        product_definition_id,
        source,
        default_language_code: lang,
        subscription_id
      }, isAws);
      window.location.href = res?.data?.data?.next_url;
    } catch (error: any) {
      setLoading(false);
      const msg = error?.response?.data?.status?.message;
      setApiError(msg);
      if (msg.includes("Email")) {
        form.resetForm();
        form.setFieldError("email", msg);
        back();
      }
    }
  };

  if (loading) return <Loading/>;

  return (
      <div className="d-flex">
        <SelectLanguageTooltipPrompt
            show={isShowTooltipModal}
            onHide={() => {
              setIsShowTooltipModal(false);
            }}
        />
        <div className="green-bg d-flex flex-1 flex-column align-items-center max-col email-input-wrap">
          <Image className="top-logo" src={noodleLogo}/>
          <div className="content-wrapper w-100" style={{paddingTop: "135px"}}>
            <div className="d-flex w-100">
              <Image
                  className="back-btn"
                  src={backArrow}
                  onClick={() => {
                    setApiError("");
                    back();
                  }}
              />
              <div
                  className="pink-color-text"
                  style={{flex: 1, textAlign: "center"}}
              >
                {t("Create an account")}
              </div>
            </div>
            <div className="input-wrapper sub-text">{t("*Required fields")}</div>
            <div className="input-wrapper">{renderInput(fields.email)}</div>

            <div className={cx("input-wrapper ", {"d-flex": !isMobile})}>
              <div className={cx({"pr-2 input-wrapper": !isMobile})}>
                {renderInput(fields.password)}
              </div>
              <div className={cx({"input-wrapper": !isMobile})}>
                {renderInput(fields.rePassword)}
              </div>
            </div>
            {showPwGuide && (
                <div className="input-wrapper opt-step">
                  <div>
                    <div>{t("An English uppercase character (A-Z)")}</div>
                    <div>{t("An English lowercase character (a-z)")}</div>
                    <div>{t("A number (0-9) or symbol (such as !, #, or %)")}</div>
                    <div>{t("More than 7 characters")}</div>
                    <div>{t("No Spaces")}</div>
                  </div>
                </div>
            )}
            <div className="input-wrapper">{renderInput(fields.name)}</div>
            <div className={cx("input-wrapper ", {"d-flex": !isMobile})}>
              <div className={cx({"pr-2 input-wrapper": !isMobile})}>
                {renderInput(fields.first_name)}
              </div>
              <div className={cx({"input-wrapper": !isMobile})}>
                {renderInput(fields.last_name)}
              </div>
            </div>
            <div className={cx("input-wrapper mb-4", {"d-flex": !isMobile})}>
              <div className={cx("mb-4", {"pr-2 input-wrapper": !isMobile})}>
                {renderInput(fields.country)}
              </div>
              <div className={cx({"input-wrapper": !isMobile})}>
                {renderInput(fields.lang)}
              </div>
            </div>
            <div className="input-wrapper d-flex justify-content-center">
              <ReCAPTCHA
                  sitekey="6LchFuQUAAAAAOJJ4_H_zt_i0-IeE1Re0utu-4FT"
                  onExpired={() => setIsVerified(false)}
                  onChange={(value) => {
                    value && setIsVerified(true);
                  }}
              />
            </div>
            <div className="btn-wrapper">
              <button
                  className="btn btn-primary w-100"
                  disabled={!form.isValid || !isVerified}
                  onClick={register}
              >
                {t("Create an account")}
              </button>
              <div className="p-1 clr-red">{apiError}</div>
            </div>
            {
                isMobile && (
                    <div className="have-an-account d-flex align-items-center pb-4">
                      {t("Already have an account?")}
                      <button
                          className="btn clr-blue font-weight-bold p-0 font-sm ml-1"
                          onClick={() => {
                            window.location.href = redUrl;
                          }}
                      >
                        {t("Click here to login")}
                      </button>
                    </div>
                )
            }
          </div>
        </div>
        {!isMobile && (
            <div className="d-flex flex-column align-items-center justify-content-center max-col">
              {/* <Image src={infoImg} /> */}
              <ChatImages/>
            </div>
        )}
        <TwoButtonModal
            show={isShowTooltip}
            leftButtonText={t("Close")}
            rightButtonText=""
            onClickRightButton={() => {
            }}
            title={t("Language")}
            message={t(
                "This would be the default language of your chatbot. You can change it later."
            )}
            size={"sm"}
            leftButtonClassName="btn btn-primary mr-3"
            onClickClose={() => {
              setIsShowTooltip(false);
            }}
            onClickLeftButton={() => {
              setIsShowTooltip(false);
            }}
        />
      </div>
  );
};

export default Info;
