import React from 'react'
import {Image, Modal, ModalFooter, Row, Col, Button} from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import {useTranslation} from "react-i18next";
import closeimg from "../../assets/images/close-blk.svg";


export default function WhatsNewPopup(props: any) {
  const {t} = useTranslation('translation');
  return (
      <Modal
          show={props.show}
          onHide={props.onHide}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName="welcome-modal lg:tw-w-1/2 md:tw-w-1/2 sm:tw-w-lg"
      >
        <ModalHeader className="pt-4 justify-content-start">
          <ModalTitle className='px-3'>
            <h1>{t("What's New")}</h1>
          </ModalTitle>
          <Button onClick={props.onHide} className="ml-auto px-3">
            <Image src={closeimg} alt=""/>
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className='px-3'>
            <Row>
              <Col className="content-center tw-mt-4 tw-mb-4">
                <video controls width="100%">
                  <source src="https://media.noodlefactory.ai/static/vid/new-webchat-voice-admin.mp4" type="video/mp4" />
                </video>
              </Col>
              <Col className='mb-4 col-12'>
                {t("We've got a new web chat interface, which features our voice-enabled AI tutor! Due to this update, we there are some updates to the Manage Agent settings in the admin portal. Read more")}&nbsp;
                <a href="https://3359170.hs-sites.com/en/knowledge/web-chat-with-voice-enabled-ai-tutor"
                   target="_blank">{("here")}.</a>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap">
          <button className="btn-primary btn" onClick={props.onHide}>{t("Close")}</button>
        </ModalFooter>
      </Modal>
  )
}
