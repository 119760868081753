import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
} from "../utils/appUtils";


const getIntegrations = async (provider: string = null, integration_type: string = null): Promise<any> => {
  let url = `extension/integrations?agent_id=${getAgentFromStorage()}`;

  if (provider !== null) {
    url += `&provider=${provider}`;
  }
  
  if (integration_type !== null) {
    url += `&integration_type=${integration_type}`;
  }

  const res = await ApiUtil.SendAsync<any>({
    url: url,
    method: "GET"
  });
  return res;
} 

const createIntegration = async (data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `extension/integrations`,
    method: "POST",
    data: data
  });
  return res;
}

const updateIntegration = async (id: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `extension/integrations/${id}?agent_id=${getAgentFromStorage()}`,
    method: "PUT",
    data: data
  });
  return res;
} 

const deleteIntegration = async (id: string): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `extension/integrations/${id}?agent_id=${getAgentFromStorage()}`,
    method: "DELETE",
  });
  return res;
} 

const getIntegrationFiles = async (provider: string, integrationId: string, course_id: string, resourceType: string): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/${integrationId}/courses/${course_id}/${resourceType}?agent_id=${getAgentFromStorage()}`,
    method: "GET",
  });
  return res;
} 

const importIntegrationFiles = async (provider: string, integrationId: string, fileType: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/${integrationId}/${fileType}?agent_id=${getAgentFromStorage()}`,
    method: "POST",
    data: data
  });
  return res.data.data;
} 

const getProviderIntegration = async (provider: string, integrationId: string, requests_new_state: boolean): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/${integrationId}?agent_id=${getAgentFromStorage()}&requests_new_state=${requests_new_state ? "true" : "false"}`,
    method: "GET",
  });
  return res.data;
} 

const integrationService = {
  getIntegrations,
  createIntegration,
  updateIntegration,
  getIntegrationFiles,
  importIntegrationFiles,
  getProviderIntegration,
  deleteIntegration
} 

export default integrationService