import { AppStateType } from "../rootReducer";

export const getCommonState = (state: AppStateType) => state.common;
export const getCommonStickersSetsFromState = (state: AppStateType) => state.common.stickersets;
export const getCommonStickersFromState = (state: AppStateType) => state.common.stickers;
export const getCommonUserDetails = (state: AppStateType) => state.common.user;
export const getNotificationsSummary = (state: AppStateType) => state.common.notificationsSummary;
export const getNewNotificationStatus = (state: AppStateType) => state.common.notificationsSummary && state.common.notificationsSummary.has_new_notification;
export const getIsSuperAdmin = (state: AppStateType) => {
    return state.common.user && (state.common.user.is_superadmin || state.common.user.role === "superadmin")
};
