import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CheckPlan from "../../assets/images/check-plan.svg";
import PlanBG from "../../assets/images/plan-bg.png";
import "./styles.scss";
import PlanSection from "./PlanSection";
import QnA from "./QnA";
import CommonService from "../../services/CommonService";
import {Link} from "react-router-dom";
import PlanCheck from "../../assets/images/plan-check.svg";

const commonService = new CommonService();

export const ManagePlan = () => {
  const {t} = useTranslation("translation");
  const PLANS = {
    EDUCATOR_PLUS: {
      isRecommended: true,
      name: "Educator Plus",
      des: t("Great for educators looking to start their AI journey"),
      priceMonth: t("Free"),
      btnText: t("Try for 14 days free"),
      isCancelAble: true,
      planIncludes: [
        t("Teacher AI toolkit"),
        t("Up to 20 document uploads"),
        t("Up to 30 students"),
        t("Up to 1 teacher/admin"),
        t("Unlimited quizzes"),
        t("Role plays"),
        t("Single agent"),
      ],
    },
    CLASSROOM_PRO: {
      isRecommended: false,
      name: "Classroom Pro",
      isCancelAble: true,
      des: t("Great for larger classes and multiple teachers"),
      priceMonth: "$59.99/" + t("month"),
      btnText: t("Select Plan"),
      planIncludes: [
        t("Everything in Educator Plus"),
        t("Up to 50 document uploads"),
        t("Up to 50 student access"),
        t("Up to 5 admins/teachers"),
        t("AI rubric-based grading"),
        t("LTI integrations"),
      ],
    },
    INSTITUTION_ESSENTIALS: {
      isRecommended: false,
      name: "Institution Essentials",
      isCancelAble: true,
      des: t("Great for K-12 and higher ed institutes"),
      priceMonth: "$99.99/" + t("month"),
      btnText: t("Select Plan"),
      planIncludes: [
        t("Everything in Classroom Pro"),
        t("Unlimited document uploads"),
        t("Up to 100 student access"),
        t("Unlimited teacher access"),
        t("Unlimited quizzes"),
        t("Multi-agent"),
      ],
    },
    BUSINESS_ESSENTIALS: {
      isRecommended: false,
      name: "Business Essentials",
      isCancelAble: true,
      des: t("Great for L&D and training"),
      priceMonth: "$99.99/" + t("month"),
      btnText: t("Select Plan"),
      planIncludes: [
        t("Access to AI toolkit"),
        t("Unlimited document uploads"),
        t("Up to 100 learner access"),
        t("Unlimited admin/trainer access"),
        t("Unlimited quizzes"),
        t("Azure SSO integration"),
        t("Role plays"),
        t("Multi-agent"),
      ],
    },
  };

  const [prods, setProds] = useState([]);
  const [allowsChangeSubscription, setAllowsChangeSubscription] = useState(false);
  const [licenses, setLicences] = useState([]);

  const getProducts = async () => {
    const res: any = await commonService.getProds();
    if (res?.data?.data?.product_definitions) {
      setProds(res?.data?.data?.product_definitions);
    }
  };

  const chunk = (arr: any[], size: number) => {
    return Array.from({length: Math.ceil(arr.length / size)}, (_: any, i: number) =>
        arr.slice(i * size, i * size + size)
    );
  }

  const getPaymentInfo = async () => {
    try {
      const res: any = await commonService.getPaymentInfo();
      if (res && res.data && res.data.data) {
        if (res.data.data.licenses.length > 0) {
          setLicences([res?.data?.data?.licenses[0]]);
        }
        if (res.data.data.allows_change_subscription) {
          setAllowsChangeSubscription(true);
          getProducts();
        } else {
          setAllowsChangeSubscription(false);
        }
      }
    } catch (error) {
      setAllowsChangeSubscription(false);
      setLicences([])
    }
  };

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const renderCompareTable = () => {
    return (
        <table className="compare-table">
          <thead>
          <tr>
            <th className=""></th>
            <th>
              <h5>{t("Educator Plus")}</h5>
            </th>
            <th>
              <div className="clr-primary">{t("(Most Popular)")}</div>
              <h5>{t("Classroom Pro")}</h5>
            </th>
            <th>
              <h5>{t("Institution Essentials")}</h5>
            </th>
            <th>
              <h5>{t("Business Essentials")}</h5>
            </th>
            {/*<th>*/}
            {/*  <h5>{t("Institution")}</h5>*/}
            {/*</th>*/}
            {/*<th>*/}
            {/*  <h5>{t("Business")}</h5>*/}
            {/*</th>*/}
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>{t("Teacher AI toolkit")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Document summarisation")}</th>
            <td>{t("Up to 20")}</td>
            <td>{t("Up to 50")}</td>
            <td>{t("Up to 100")}</td>
            <td>{t("Up to 100")}</td>
            {/*<td>{t("Unlimited")}</td>*/}
            {/*<td>{t("Unlimited")}</td>*/}
          </tr>
          <tr>
            <th>{t("Automated learning outcomes")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Automated tutoring pathways")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("AI-generated assessments")}</th>
            <td>{t("Up to 10")}</td>
            <td>{t("Up to 10")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            {/*<td>{t("Unlimited")}</td>*/}
            {/*<td>{t("Unlimited")}</td>*/}
          </tr>
          <tr>
            <th>{t("AI grading")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Increased document size limits")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Multiple teachers access")}</th>
            <td>-</td>
            <td>{t("Up to 5")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            {/*<td>{t("Unlimited")}</td>*/}
            {/*<td>{t("Unlimited")}</td>*/}
          </tr>
          <tr>
            <th>{t("Unlimited document uploads")}</th>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Unlimited user access")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("LTI integrations")}</th>
            <td>-</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Workflow integrations")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          <tr>
            <th>{t("Rubric-based AI essay grading")}</th>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>-</td>
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <img className="check-plan" src={CheckPlan} alt=""/>*/}
            {/*</td>*/}
          </tr>
          </tbody>
        </table>
    );
  };

  const renderQuestions = () => {
    const ques = [
      {
        q: t("Does Walter support multiple languages?"),
        a: () => (
            <p className="font-16">
              {t("Absolutely! Walter supports over 80 languages today!")}
            </p>
        ),
      },
      {
        q: t("What's the quickest way to learn how to use Walter?"),
        a: () => (
            <p className="font-16">
              {t("Our")}{" "}
              <Link
                  className="reach-out"
                  to="/support"
              >
                {t("Support Page")}
              </Link>{" "}
              {t(
                  "offers video guides that will make learning Walter a breeze. Simply log in, access the guides, and become a Walter expert in record time! We also provide 24/7 live chat support."
              )}
            </p>
        ),
      },
      {
        q: t("What if I reach my plan's limit?"),
        a: () => (
            <p className="font-16">
              {t(
                  "When you exceed your plan's limit, you will need to upgrade to a higher plan to increase your capacity. For larger deployments with over 50 users, we recommend considering a customised plan tailored to your specific requirements. Get in touch with us at"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>{" "}
              {t("to explore your options and take the next steps!")}
            </p>
        ),
      },
      {
        q: t("What is your refund policy?"),
        a: () => (
            <p className="font-16">
              {t(
                  "Discover the power of Walter as part of your Educator Essential Plan, or test it during your 14-day free trial period with the Educator Plus Plan and decide if it's right for you. You can upgrade, downgrade, or cancel anytime as your needs evolve. For account assistance, reach out to"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>
              {" ."}
              {t("We're here to support you every step of the way!")}
            </p>
        ),
      },
      {
        q: t("Am I eligible for a discounted plan?"),
        a: () => (
            <p className="font-16">
              {t(
                  "If you've had a fantastic trial experience with Walter and want to bring him into your school or business, our tailored packages ensure you get the best value and features to meet your school's or business' needs. Ready to learn more? Email us at"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>
              {", "}
              {t("and we'll provide you with all the exciting details.")}
            </p>
        ),
      },
      {
        q: t("Is there a specific type of school that is best suited for Walter?"),
        a: () => (
            <p className="font-16">
              {t(
                  "Our AI platform thrives in various learning environments! We've witnessed great success in higher education, vocational training programs, adult learning centres, and K-12 schools, particularly with students from Grade 8 onwards. Regardless of the type of institution, our AI platform is designed to enhance teaching and learning experiences."
              )}
            </p>
        ),
      },
      {
        q: t("Can I use Walter in a business setting?"),
        a: () => (
            <p className="font-16">
              {t(
                  "Definitely! Our AI platform is versatile and well-suited for various learning environments, including business settings. It is great for a range of use cases, such as onboarding new employees, supporting employee training programs, compliance training, and information management. Whether you're a small business or a large corporation, Walter can be tailored to meet your specific needs in a business setting."
              )}
            </p>
        ),
      },
    ];

    return ques.map((q) => <QnA q={q}/>);
  };

  const getProd = (name: any) => {
    const prod = prods.find((p) => p.name === name);
    if (prod) {
      return prod;
    }
    return null;
  };

  return (
      <section className="page-mid-wraper h-without-foter">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-12 d-flex justify-content-between align-items-center">
              <h1 className="mb-0">{t("Manage Plans")}</h1>
            </div>
            {!allowsChangeSubscription && (
                <div className="current-subscription">
                  <div>
                    <h3 className="mb-3 font-weight-bold">{t("Current Plan")}</h3>
                    {
                        (licenses.length > 0) && licenses.map(({features, product_definition, status, tier,}): any => {
                          return (
                              <div className="plan-section-wrapper w-100">
                                <div className="row">
                                  <div className="col-4 border-right">
                                    <div className="name">{product_definition?.name}</div>
                                    <div className="sub mb-24">
                                      {product_definition?.description}
                                    </div>
                                    <div>
                                    </div>
                                  </div>
                                  <div className="col-8">
                                    <div className="feature-label tw-mb-4 pt-0 pb-3">{t("Features")}</div>
                                    <div className="all-features">
                                      {(features || []).map((p: any) => (
                                          <div className="feature-item d-flex align-items-center">
                                            <img src={PlanCheck} alt=""/>
                                            <span className="feature"> {t(p)}</span>
                                          </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          )
                        })
                    }
                  </div>
                </div>
            )}
            {allowsChangeSubscription && (
                <>
                  <div className="tw-text-center">
                    <div className="w-100 d-relative plan-bg col-md-12">
                      <img src={PlanBG} alt=""/>
                      <div className="center-banner-text tw-text-left tw-hidden lg:tw-block">
                        <div className="banner-text">
                          <span className="clr-primary">{t("Unblock Your")}</span>{" "}
                          <span className="clr-hight-light">{t("Superpowers")}</span>
                        </div>
                        <div>
                          <h1>{t("with our AI teaching assistant")}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center heading-plan p-4">
                    {t("Flexible pricing to scale with your needs")}
                  </div>
                  {/*Split PLANS into chunks with chunk_size = 2 then render each 2 plans in a section*/}
                  {chunk(Object.values(PLANS), 2).map((plans) => {
                    return (
                        <section className="row my-3">
                          {plans.map((plan) => {
                            return (
                                <div className="col-md-6">
                                  <PlanSection
                                      {...plan}
                                      url={getProd(plan.name)?.url}
                                      isCurrent={getProd(plan.name)?.is_current}
                                      isRecommended={getProd(plan.name)?.is_recommended}
                                  />
                                </div>
                            )
                          })}
                        </section>
                    );
                  })}

                  {/*<section className="my-3 pt-5">*/}
                  {/*  <div className="text-center">*/}
                  {/*    <b className="font-18">{t("Plan Features")}</b>*/}
                  {/*  </div>*/}
                  {/*  <div className="text-center pb-5">*/}
                  {/*    <h1 className="font-18">{t("What's The Best Fit")}</h1>*/}
                  {/*  </div>*/}
                  {/*  {renderCompareTable()}*/}
                  {/*</section>*/}
                  <section className="font-1 text-center py-3">
                    {t("Can't decide? The Noodlers are here to help!")}
                    <a
                        className="reach-out"
                        href="https://info.noodlefactory.ai/contact-sales"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                      {" "}
                      {t("Reach Out Now")}
                    </a>
                  </section>
                  <section className="pt-5">
                    <div className="text-center">
                      <b className="font-18">{t("Answers to")}</b>
                    </div>
                    <div className="text-center">
                      <h1>{t("Frequently Asked Questions")}</h1>
                    </div>
                    <div className="qnas mt-5">{renderQuestions()}</div>
                  </section>

                </>
            )}
          </div>
        </div>
      </section>
  );
};

export default ManagePlan;
